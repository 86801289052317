<template>
  <div class="prices top">
    <Section class="big-info pt2 works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>Our Studio Prices</h1>
          <p>
            We offer very competitive prices for all of our services.
          </p>
        </Column>
      </Grid>

      <div class="price">
        <table>          
          <tr class="header">
            <th><strong>For Her</strong></th>
            <th>
              Stylist
            </th>
            <th>Master Stylist</th>
            <th>Creative<br />Style<br />Director</th>
          </tr>

          <tr>
            <th>Cut &amp; Finish</th>
            <td>£32</td>
            <td>£37</td>
            <td>£41</td>
          </tr>

          <tr>
            <th>Dry Cut or Wet Cut</th>
            <td>£16</td>
            <td>£23</td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Blowdry</th>
            <td>£15</td>
            <td>£19</td>
            <td>£22</td>
          </tr>

          <tr>
            <th>Hair art from</th>
            <td>£25</td>
            <td>£27</td>
            <td>£28</td>
          </tr>

          <tr>
            <th>Kerastraight blowdry from</th>
            <td>£65</td>
            <td>£75</td>
            <td>£80</td>
          </tr>

          <tr>
            <th><i>Wedding packages available.</i></th>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>For Him</strong></th>
            <th>
              Stylist
            </th>
            <th>Master Stylist</th>
            <th>Creative<br />Style<br />Director</th>
          </tr>

          <tr>
            <th>Lads cut and finish (age 10-16)</th>
            <td>£15</td>
            <td>£16</td>
            <td>£18</td>
          </tr>

          <tr>
            <th>Gents cut and finish</th>
            <td>£21</td>
            <td>£23</td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Colour</th>
            <td>£21</td>
            <td>£23</td>
            <td>£27</td>
          </tr>

          <tr>
            <th>Highlights</th>
            <td>£25</td>
            <td>£27</td>
            <td>£29</td>
          </tr>

          <tr>
            <th><i>Wet cut throat shave available, consultation required for the first time.</i></th>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Young Looks</strong></th>
            <th>
              Stylist
            </th>
            <th>Master Stylist</th>
            <th>Creative<br />Style<br />Director</th>
          </tr>

          <tr>
            <th>Child's cut (under age 5)</th>
            <td>£6</td>
            <td>£11</td>
            <td>£13</td>
          </tr>

          <tr>
            <th>Child's dry cut (age 5-11)</th>
            <td>£11</td>
            <td>£14</td>
            <td>£16</td>
          </tr>

          <tr>
            <th>Child's wash, cut and finish (age 5-11)</th>
            <td>£16</td>
            <td>£19</td>
            <td>£21</td>
          </tr>

          <tr>
            <th>Teens dry cut (age 12-16)</th>
            <td>£13</td>
            <td>£21</td>
            <td>£23</td>
          </tr>

          <tr>
            <th>Teens wash, cut and finish</th>
            <td>£16</td>
            <td>£29</td>
            <td>£29</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Colour</strong></th>
            <th>
              Stylist
            </th>
            <th>Master Stylist</th>
            <th>Creative<br />Style<br />Director</th>
          </tr>

          <tr>
            <th>Bonding Colour Mask Chromda ID</th>
            <td>£12</td>
            <td>£12</td>
            <td>£12</td>
          </tr>

          <tr>
            <th>Additional Colour</th>
            <td>£6</td>
            <td>£6</td>
            <td>£6</td>
          </tr>

          <tr>
            <th>Foils package (no more than 15 foils)</th>
            <td>£16</td>
            <td>£22</td>
            <td>£23</td>
          </tr>

          <tr>
            <th>Foils half head</th>
            <td>£37</td>
            <td>£37</td>
            <td>£40</td>
          </tr>

          <tr>
            <th>Foils full head</th>
            <td>£48</td>
            <td>£48</td>
            <td>£52</td>
          </tr>

          <tr>
            <th>Per foil</th>
            <td>£4</td>
            <td>£5</td>
            <td>£5</td>
          </tr>

          <tr>
            <th>Full head colour short</th>
            <td>£30</td>
            <td>£39</td>
            <td>£40</td>
          </tr>

          <tr>
            <th>Full head colour long</th>
            <td>£34</td>
            <td>£45</td>
            <td>£46</td>
          </tr>

          <tr>
            <th>Root re-touch</th>
            <td>£22</td>
            <td>£31</td>
            <td>£32</td>
          </tr>

          <tr>
            <th>Semi-permanent short</th>
            <td>£15</td>
            <td>£22</td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Semi-permanent long</th>
            <td>£22</td>
            <td>£26</td>
            <td>£28</td>
          </tr>

          <tr>
            <th>Fibre plex colour lifting (consultation needed - please call salon)</th>
            <td>From £21</td>
            <td>From £22</td>
            <td>From £23</td>
          </tr>

          <tr>
            <th>Ombre / balayage specialist technique</th>
            <td>From £61</td>
            <td>From £73</td>
            <td>From £77</td>
          </tr>

          <tr>
            <th>Fashion colour</th>
            <td>£17</td>
            <td>£23</td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Toners from</th>
            <td>£10</td>
            <td>£10</td>
            <td>£10</td>
          </tr>

          <tr>
            <th>Fibre plex sealer</th>
            <td>£10</td>
            <td>£10</td>
            <td>£10</td>
          </tr>
        
          <tr>
            <th>Fibre clinix specialist treatment</th>
            <td>£10</td>
            <td>£10</td>
            <td>£10</td>
          </tr>

          <tr>
            <th>Blonde me treatment</th>
            <td>£7</td>
            <td>£7</td>
            <td>£7</td>
          </tr>

          <tr>
            <th>Blonde me specialist colour add on</th>
            <td>£10</td>
            <td>£10</td>
            <td>£10</td>
          </tr>

          <tr>
            <th>Oil treatment</th>
            <td>£7</td>
            <td>£7</td>
            <td>£7</td>
          </tr>          

          <tr>
            <th>Express treatment</th>
            <td>£5</td>
            <td>£5</td>
            <td>£5</td>
          </tr>

          <tr>
            <th>Express repair treatment</th>
            <td>£4</td>
            <td>£4</td>
            <td>£4</td>
          </tr>

          <tr>
            <th>Intense treatment</th>
            <td>£8</td>
            <td>£8</td>
            <td>£8</td>
          </tr>

          <tr>
            <th>Fibre force with shot (intense repair)</th>
            <td>£12</td>
            <td>£12</td>
            <td>£12</td>
          </tr>

          <tr>
            <th>Fibre treatment </th>
            <td>£13</td>
            <td>£13</td>
            <td>£13</td>
          </tr>
        </table>
      </div>
    </Section>

    <Section class="big-info pt2 works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>Our Beauty Room Prices</h1>
          <p>
            Take the weight off your feet and let your aches and pains flow away with our relaxing treatments.
          </p>
        </Column>
      </Grid>

      <div class="price">
        <table>
          <tr class="header">
            <th><strong>Gel Manicure & Pedicures</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Gel Manicure<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£23</td>
          </tr>

          <tr>
            <th>Gel Pedicure<br><i>35 minutes</i></th>
            <td></td>
            <td></td>
            <td>£23</td>
          </tr>

          <tr>
            <th>Gel Manicure - French Polish<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£25</td>
          </tr>

          <tr>
            <th>Builder Gel Enhancement<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£26</td>
          </tr>

          <tr>
            <th>Gel Removal<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£6</td>
          </tr>

          <tr>
            <th>Dry Cut or Wet Cut</th>
            <td>£15</td>
            <td>£22</td>
            <td>£23</td>
          </tr>

          <tr>
            <th>Blowdry</th>
            <td>£14</td>
            <td>£18</td>
            <td>£21</td>
          </tr>

          <tr>
            <th>Hair art from</th>
            <td>£20</td>
            <td>£26</td>
            <td>£27</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Nail Extensions</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Acrylic Nails<br><i>125 minutes</i></th>
            <td></td>
            <td></td>
            <td>£35</td>
          </tr>

          <tr>
            <th>Nail Infill<br><i>90 minutes</i></th>
            <td></td>
            <td></td>
            <td>£25</td>
          </tr>

          <tr>
            <th>Full on Tips<br><i>90 minutes</i></th>
            <td></td>
            <td></td>
            <td>£31</td>
          </tr>

          <tr>
            <th>Full on Tips Infill<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£28</td>
          </tr>

          <tr>
            <th>Nail Removal<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£10</td>
          </tr>
        </table>
        <p><i>*Glitter, stamping, foil and other art may be charged extra - please discuss at the time of booking.</i></p>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Polish Manicure and Pedicure</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Luxury Pedicure<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Luxury Manicure<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£24</td>
          </tr>

          <tr>
            <th>Bella Spa Pedicure<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£19</td>
          </tr>

          <tr>
            <th>Bella Spa Manicure<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£19</td>
          </tr>

          <tr>
            <th>Express Pedicure<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£14</td>
          </tr>

          <p><i>*All the above include a varnish finish. If you would like a gel veneer finish it is an additional £12.</i><p> 
          
          <tr>
            <th>Varnish Enhancement Toes<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£11</td>
          </tr>

          <tr>
            <th>Varnish Enhancement Hands<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£11</td>
          </tr>

          <tr>
            <th>Child's File and Polish<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£6</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Eyebrows & Eyelash Treatments</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Eyebrow Shape<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Eyebrow Tint *<br><i>25 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Eyelash Tint *<br><i>35 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Eyebrow Shape, Eyebrow & Eyelash Tint *<br><i>35 minutes</i></th>
            <td></td>
            <td></td>
            <td>£20</td>
          </tr>

          <tr>
            <th>Eyebrow Reshape<br><i>(1st Visit) 25 minutes</i></th>
            <td></td>
            <td></td>
            <td>£14</td>
          </tr>

          <p><i>*These treatments require a patch test at least 48 hours before appointment.</i></p>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Eyes</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>LVL Lashes *<br><i>75 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>

          <tr>
            <th>HD Brows *<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£26</td>
          </tr>

          <tr>
            <th>HD Brow Sculpt (Lamination) *<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>

          <tr>
            <th>Express Lashes (Party/Cluster Lash)<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£26</td>
          </tr>

          <tr>
            <th>Eyelash Extensions - Classic Semi-Permanent<br><i>120 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>

          <p><i>*These treatments require a patch test at least 48 hours before appointment.</i></p>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Facials</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Facial - Dermalogica Pro 60<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>

          <tr>
            <th>Facial - Dermalogica Pro 30<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£30</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Ladies' Facial Waxing</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Lip Wax<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

            <tr>
            <th>Chin Wax<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Nostril Wax<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Other Facial Waxing<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Ladies' Intimate Waxing</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Ladies' Leg Waxing - Half Leg Wax<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£16</td>
          </tr>

          <tr>
            <th>Ladies' Leg Waxing - Full Leg Wax<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£21</td>
          </tr>

            <tr>
            <th>Underarm<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£16</td>
          </tr>

          <tr>
            <th>Forearm<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£16</td>
          </tr>

          <tr>
            <th>Bikini Wax<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£13</td>
          </tr>

            <tr>
            <th>G-String Wax<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£17</td>
          </tr>

          <tr>
            <th>Brazilian Waxing<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£26</td>
          </tr>

          <tr>
            <th>Hollywood Waxing<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£31</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Massage</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Back, Neck & Shoulders<br><i>75 minutes</i></th>
            <td></td>
            <td></td>
            <td>£30</td>
          </tr>

          <tr>
            <th>Full Body Massage<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>

            <tr>
            <th>Swedish Massage<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£32</td>
          </tr>

          <tr>
            <th>Swedish Massage<br><i>60 minutes</i></th>
            <td></td>
            <td></td>
            <td>£47</td>
          </tr>

          <tr>
            <th>Forearm<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£16</td>
          </tr>

          <tr>
            <th>Target Area<br><i>15 minutes - Add to any above, choose from arms, legs, feet, neck & shoulders or back</i></th>
            <td></td>
            <td></td>
            <td>£15</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Body Care</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Express Full Body Cleanse & Exfoliation<br><i>20 minutes</i></th>
            <td></td>
            <td></td>
            <td>£30</td>
          </tr>

          <tr>
            <th>Bella Express Spa Cleanse, Exfoliation & Moisturise with 20 minute Massage<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£35</td>
          </tr>

            <tr>
            <th>Bella Spa - Cleanse, Exfoliation & Moisturise with 30 minute Massage<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£40</td>
          </tr>

          <tr>
            <th>Luxury Spa - Cleanse, Exfoliation, Deep dermal Wrap Moisturise with 30 minute massage & Moisturise with 30 minute Massage<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£45</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Male Grooming</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Waxing - Under Arm<br><i>25 minutes</i></th>
            <td></td>
            <td></td>
            <td>£18</td>
          </tr>

          <tr>
            <th>Waxing - Half Arm<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£16</td>
          </tr>

            <tr>
            <th>Waxing - Full Arm<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£21</td>
          </tr>

          <tr>
            <th>Waxing - Back & Shoulders<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>From £26</td>
          </tr>

          <tr>
            <th>Waxing - Chest<br><i>40 minutes</i></th>
            <td></td>
            <td></td>
            <td>From £26</td>
          </tr>

          <tr>
            <th>Nostril Wax<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Eyebrow Shape<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Ear Waxing<br><i>15 minutes</i></th>
            <td></td>
            <td></td>
            <td>£9</td>
          </tr>

          <tr>
            <th>Eyebrow, Nostril & Ear Waxing<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£20</td>
          </tr>

          <tr>
            <th>Men's Manicure<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£21</td>
          </tr>

          <tr>
            <th>Men's Pedicure<br><i>45 minutes</i></th>
            <td></td>
            <td></td>
            <td>£21</td>
          </tr>
        </table>

        <br />
        <br />
        <table>
          <tr class="header">
            <th><strong>Tanning</strong></th>
            <th>
            </th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th>Spray Tanning & Sunless Tanning<br><i>30 minutes</i></th>
            <td></td>
            <td></td>
            <td>£20</td>
          </tr>
        </table>
      </div>
    </Section>

    <Section center pad wrap class="big-info gradient-back invert">
      <h1>Create the <em>new you</em> today!</h1>
      <p>
        Interested in getting a <b>fresh</b> new look for yourself? Want to feel
        <em>great</em> about yourself?
      </p>
      <router-link to="/visiting" class="button big">Visit Us</router-link>
    </Section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.works {
  img {
    width: 100%;
    border: 0.3em solid white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);

    @include media($breakpoint-below-laptop) {
      margin-bottom: -2em;
    }
  }

  .col:first-child img {
    transform: rotate(-4deg);
  }

  .col:last-child img {
    transform: rotate(4deg);

    @include media($breakpoint-below-laptop) {
      margin-bottom: 2em;
    }
  }
}

.price {

  p {
    font-size: 1em !important;
  }

  table {
    width: 100%;
    font-size: 1em;
    line-height: 1.2em;

    p {
      font-size: 1em;
    }

    @include media($breakpoint-below-tablet) {
      font-size: 0.7em;
    }

    @include media($breakpoint-tiny-mobile) {
      th,
      td {
        padding: 0.7em !important;
      }
    }

    border-collapse: collapse;

    border: 1px solid black;
    padding: 1em;

    tr {
      width: 100%;
      &:nth-child(even) {
        background: #f3f3f3;
      }
    }

    tr:first-child {
      background: #ddd;
    }

    .header {
      th strong {
        font-size: 2.5em;
        vertical-align: middle;

        @include media($breakpoint-below-laptop) {
          font-size: 1.5em;
        }
      }

      td,
      th {
        padding: 1em;
      }
    }

    th,
    td {
      padding: 1em;
      //padding-bottom: 0;
    }

    tr:last-child {
      td,
      th {
        padding-bottom: 2em;
      }
    }

    tr:nth-child(2) {
      td,
      th {
        padding-top: 2em;
      }
    }

    td:not(:first-child),
    th:not(:first-child) {
      text-align: center;
      width: 1px;
      white-space: nowrap;
    }

    th:first-child {
      font-weight: bold;
      text-align: left;
    }
  }
}
</style>
