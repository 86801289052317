<template>
  <div class="home top">
    <ViewportHeight>
      <FullBackground class="hero" :opacity="0.2" :image="splashImageUrl">
        <Section center middle pad wrap>
          <Grid center class="height100">
            <Column
              center
              middle
              :columnWidth="66"
              below-tablet-text-center
              :below-tablet-width="100"
            >
              <h1>
                Modern hair stylists in
                <span class="text-no-wrap">Stoke-on-Trent</span>
              </h1>
              <p>
                Trusted by 17 years of clients coming back for a classic cut and
                colour, revamps or full modern make-overs...
              </p>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <Section class="big-info image-text gradient-back" wrap pad>
      <Grid pad center middle>
        <Column center :below-laptop-width="80" :column-width="50">
          <img :src="iconHairUrl" />
        </Column>
        <Column
          :below-laptop-width="100"
          :column-width="50"
          below-laptop-text-center
        >
          <h2>Rest.&emsp;Relax.&emsp;Recharge</h2>
          <h1>
            Welcome to the<br />
            Home of <em style="color: gold">Colour</em><br />
          </h1>
          <p>
            With our collective experience from our stylists accumulating over
            30 years, it's easy to see why so many clients keep coming back to
            us to create their style.
          </p>
        </Column>
      </Grid>
    </Section>

    <Section class="pl2 pr2" wrap center>
      <Grid pad>
        <Column :below-laptop-width="50">
          <div class="iconic-header">
            <h1>40yr</h1>
            <p>experience</p>
          </div>
        </Column>
        <Column :below-laptop-width="50">
          <div class="iconic-header">
            <h1>68k+</h1>
            <p>Haircuts</p>
          </div>
        </Column>
        <Column :below-laptop-width="50">
          <div class="iconic-header">
            <h1>10k+</h1>
            <p>individual clients</p>
          </div>
        </Column>
        <Column :below-laptop-width="50">
          <div class="iconic-header">
            <h1>£500k</h1>
            <p>products used</p>
          </div>
        </Column>
      </Grid>
    </Section>

    <Section center pad wrap>
      <Grid center>
        <Column :column-width="80">
          <div class="big-info pb2">
            <p>
              We love the new styles and <b><em>ideas</em></b> that our clients
              bring to us. Do you have a particuar style you wish for us to
              <b>create?</b>
            </p>
          </div>
        </Column>
        <Column :column-width="70" :below-laptop-width="90">
          <div class="banner">
            <p>
              If you care about your hair and what your stylist does with it
              then you have come to the right place.
            </p>
          </div>
        </Column>
      </Grid>
    </Section>

    <!-- Luke -->
    <Section class="big-info works gradient-back invert" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Choppy &amp;<br />
            <em>Colours</em>
          </h1>
          <p>
            Luke is a long time client (<em>and our web designer</em>) who loves
            colours and <b>big hair</b>. There are not many hair styles or
            colours he hasn't tried.
          </p>
        </Column>
      </Grid>

      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="luke1Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="luke2Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="luke3Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="luke4Url" />
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="luke5Url"> </FullBackground>
    </div>

    <!-- Client A -->
    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Curly &amp;<br />
            <em>Bright</em>
          </h1>
          <p>
            Cathy has been coming to Charlie Browns for over 7 years and loves
            anything that’s bright and <b>different</b>. Naturally, we find only
            the best and most <em>vibrant</em> colour we can to suit her style.
          </p>
        </Column>
      </Grid>

      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="clientA1Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="clientA2Url" />
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="clientA3Url"> </FullBackground>
    </div>

    <!-- Client B -->
    <Section class="big-info works gradient-back invert" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Weddings &amp;<br />
            <em>Special Events</em>
          </h1>
          <p>
            Aman was planning her <em>wedding</em> when she first come to us and
            after a few salon visits for colour and cut we had the
            <b>honour</b> of doing her wedding hair. Now she vists the salon
            regularly for complete colour change to suit her current trend.
          </p>
        </Column>
      </Grid>

      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="clientB1Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="clientB2Url" />
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="clientB3Url"> </FullBackground>
    </div>

    <!-- Client A -->
    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Family<br />
            <em>Package</em>
          </h1>
          <p>
            We offer great rates for <b>whole families</b>. Ask for for
            <em>discounts</em> for inviting your family members!
          </p>
        </Column>
      </Grid>

      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="clientF1Url" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="clientF2Url" />
        </Column>
      </Grid>
    </Section>

    <Section center pad wrap class="big-info">
      <h1>Create the <em>new you</em> today!</h1>
      <p>
        Interested in getting a <b>fresh</b> new look for yourself? Want to feel
        <em>great</em> about yourself?
      </p>
      <router-link to="/visiting" class="button big">Visit Us</router-link>
    </Section>

    <iframe>
      <div id="popup-rating-widget"><script type="application/javascript" id="popup-rating-widget-script" src="https://widget.reviewability.com/js/popupWidget.min.js" data-gfspw="https://app.revu.cloud/popup-pixel/get/ac12ec5635272f1a2d84ad9787db85dce477fb94" async></script></div>
    </iframe>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/heros/home.jpg",
      iconHairUrl: "/images/icons/hair.svg",
      luke1Url: "/images/hair/luke1.jpg",
      luke2Url: "/images/hair/luke2.jpg",
      luke3Url: "/images/hair/luke3.jpg",
      luke4Url: "/images/hair/luke4.jpg",
      luke5Url: "/images/hair/luke5.jpg",
      clientA1Url: "/images/hair/clientA1.jpg",
      clientA2Url: "/images/hair/clientA2.jpg",
      clientA3Url: "/images/hair/clientA3.jpg",
      clientB1Url: "/images/hair/clientB1.jpg",
      clientB2Url: "/images/hair/clientB2.jpg",
      clientB3Url: "/images/hair/clientB3.jpg",
      clientF1Url: "/images/hair/family1.jpg",
      clientF2Url: "/images/hair/family2.jpg"
    };
  }
};
</script>

<style lang="scss">
#app[data-page="home"] {
  --color-nav-link: white;
}

.home .background-item {
  background-color: black !important;
}

.home {
  .hero {
    color: white;
  }
}

.image-text {
  img {
    width: 80%;
  }
}

.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.iconic-header {
  h1 {
    font-size: 4em;
    margin: 0;
    line-height: 1.2em;

    @include media($breakpoint-below-laptop) {
      font-size: 2.5em;
    }
  }
  p {
    font-weight: bold;
    font-size: 1.5em;
    @include media($breakpoint-below-laptop) {
      font-size: 1.2em;
    }
    margin: 0;
  }
}

.works {
  img {
    width: 100%;
    border: 0.3em solid white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);

    @include media($breakpoint-below-laptop) {
      margin-bottom: -2em;
    }
  }

  .col:first-child img {
    transform: rotate(-4deg);
  }

  .col:last-child img {
    transform: rotate(4deg);

    @include media($breakpoint-below-laptop) {
      margin-bottom: 2em;
    }
  }
}

iframe{
  border-color: none !important;
}
</style>
