<template>
  <div class="products">
    <!-- Hero -->
    <ViewportHeight top>
      <FullBackground class="hero" :opacity="0.5" :image="splashImageUrl">
        <Grid fill wrap vertical no-grow center>
          <Column center class="pad2 hero-text">
            <Grid center>
              <Column :column-width="80" :below-laptop-width="100">
                <h1>
                  Nothing but the best<br />
                  products for <em>you</em>
                </h1>
                <p>
                  Over the years we have found what works best for modern hair
                  care needs.
                </p>
              </Column>
            </Grid>
          </Column>
          <Column fill class="range">
            <div
              :style="{ 'background-image': `url('${rangeImageUrl}')` }"
            ></div>
          </Column>
        </Grid>
      </FullBackground>
    </ViewportHeight>

    <!-- Statement -->
    <Section pad wrap center class="big-info gradient-back invert">
      <h2>Industry Leading Suppliers</h2>
      <h1>
        We use only the best products to get the best results without
        compromising on the health of your hair.
      </h1>
      <p>
        Healthy, shiny and full of nourishment, all the hallmarks that come from
        using the best quality hair care products.
        <em>We accept nothing less.</em>
        <br />
      </p>
    </Section>

    <!-- GHD -->
    <Section pad wrap class="big-info ghd">
      <Grid pad center>
        <Column :tablet-width="90" :mobile-width="100">
          <div>
            <h1 class="tight">
              We are daring. We are ambitious. We are <em>ghd</em>
            </h1>
            <p>
              We champion beauty, in all that we do, with one very clear purpose
              in mind: to empower all women so they can fulfil their every
              ambition.
            </p>
          </div>
        </Column>
        <Column middle :tablet-width="70" :below-tablet-width="90">
          <div>
            <img :src="ghdLogoUrl" />
          </div>
        </Column>
      </Grid>
    </Section>

    <!-- GHD Box -->
    <Section wrap class="ghd-box"> <img :src="ghdBoxUrl" /> </Section>

    <Section
      wrap
      pad
      center
      class="big-info gradient-back invert american-crew"
    >
      <h2>For the men</h2>
      <img class="pt2 pb2" :src="americanCrewLogoUrl" />
      <p>
        Discover the products that make us the top men’s hair styling brand in
        the world. Achieve the look you want with our award winning portfolio.
        Find the perfect hair care product specially formulated for shine,
        volume, texture and hold.
      </p>
    </Section>

    <!-- American Crew -->
    <ViewportHeight>
      <FullBackground :image="americanCrewUrl"></FullBackground>
    </ViewportHeight>

    <!-- Schwarzkopf -->
    <ViewportHeight>
      <Section fill wrap center class="big-info schwarzkopt">
        <Grid center class="height100">
          <Column middle :column-width="60" :below-laptop-width="100">
            <div class="pad2">
              <img :src="schwarzkopfLogoUrl" />
              <p>
                We are one. Bound together by our love of hair. We are fueled by
                the same fire and devotion to our craft. Our collective ambition
                to excel drives our spirit. To question. To explore. To outdo
                what’s been done before.
              </p>
            </div>
          </Column>
          <Column
            class="blush-wash"
            :column-width="40"
            :below-laptop-width="100"
          >
            <div>
              <div></div>
            </div>
          </Column>
        </Grid>
      </Section>
    </ViewportHeight>

    <!-- Schwarzkopt Splash -->
    <ViewportHeight>
      <FullBackground class="colors" :opacity="0.3" :image="colorsUrl">
        <Section class="big-info" fill center wrap pad>
          <Grid center middle fill>
            <Column :below-laptop-width="80" :column-width="60">
              <h1>
                Making a <em style="color: red">statement</em> or keeping it
                natural?
              </h1>
              <p>
                Whether you’re planning on attending a bank holiday party and
                want to stand out or you simply fancy a change, with many high
                quality colouring products on the market, it’s so easy to get
                the shade you’ve always wanted.
              </p>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <!-- Igora -->
    <Section class="big-info igora gradient-back" wrap pad>
      <Grid center middle>
        <Column :below-laptop-width="100" :column-width="50">
          <h2>Igora Royal</h2>
          <h1>Amazing fade resistant, long lasting <em>colour.</em></h1>
          <p>
            Schwarzkopf Igora Royal is one of the most reliable and vibrant
            permanent hair colour products available to colourists today. It
            truly lives up to the claim that it brings ‘true colour in High
            Definition’, even on porous hair that can, at times, be tough to
            work on. Something to do with them being long lasting, fade
            resistent etc..
          </p>
        </Column>
        <Column center :below-laptop-width="100" :column-width="50">
          <img :src="igoraTubeUrl" />
        </Column>
      </Grid>
    </Section>

    <!-- Schwarzkopt Splash -->
    <Section center pad wrap>
      <Grid center>
        <Column :column-width="80">
          <div class="big-info pt2 pb2">
            <p>
              If there is any products <em>you would like</em> that we
              <b>do not</b> stock, feel free to ask us about them and we can
              contact our suppliers and work something out.
            </p>
          </div>
        </Column>
        <Column :column-width="70" :below-laptop-width="90">
          <div class="banner">
            <p>
              With over <em>17 years</em> of experience you can rest assured we
              only use the best.
            </p>
            <p>Relax and let our staff cater for all your hair needs.</p>
          </div>
        </Column>
      </Grid>
    </Section>
  </div>
</template>

<script>
export default {
  name: "products",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/backgrounds/abstract-geometry.svg",
      rangeImageUrl: "/images/heros/products.png",
      ghdLogoUrl: "/images/range/logo-ghd.svg",
      schwarzkopfLogoUrl: "/images/range/logo-schwarzkopf.png",
      ghdBoxUrl: "/images/range/ghd.jpg",
      americanCrewLogoUrl: "/images/range/logo-american-crew.svg",
      americanCrewUrl: "/images/range/products.jpg",
      colorsUrl: "/images/range/colors.jpg",
      igoraTubeUrl: "/images/range/igora.png"
    };
  }
};
</script>

<style lang="scss" scoped>
.ghd {
  @include media($breakpoint-below-laptop) {
    text-align: center;

    p {
      margin-bottom: 3em;
    }
  }
}

.igora {
  @include media($breakpoint-below-laptop) {
    text-align: center;
  }
}

.ghd-box {
  margin-top: -6em;

  @include media($breakpoint-below-laptop) {
    margin-top: 0em;
  }
  img {
    width: 100%;
  }
}

.american-crew {
  img {
    width: 50%;
  }
}

.colors {
  background: black !important;

  color: var(--color-dark-background-text);
}

.schwarzkopt {
  @include media($breakpoint-below-laptop) {
    padding-top: 3em;
  }

  img {
    width: 70%;
  }
  .blush-wash {
    @include media($breakpoint-below-laptop) {
      height: 50%;
    }

    overflow: hidden;
    height: 100%;

    > div {
      height: 100%;

      @include media($breakpoint-below-laptop) {
        height: 150%;
      }
      > div {
        background-image: url("/images/range/blush-wash.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;

        // Float right side
        @include media($breakpoint-above-tablet) {
          position: absolute;
          background-position: left 10em;
          left: 55vw;
          right: 0;
        }

        @include media($breakpoint-laptop) {
          left: 50vw;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 25%;
          bottom: 0;
          background: linear-gradient(to bottom, transparent, #fff);
        }
      }
    }
  }
}

.range {
  width: 100%;

  > div {
    height: 100%;

    background-position: top center;
    background-size: cover;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

.hero {
  border-bottom: 2px solid;
  border-color: var(--color-primary);

  h1 {
    margin-bottom: 0.8em;
  }
}
</style>
