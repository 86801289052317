<template>
  <div id="app" :data-page="$pageName">
    <NavigationMenu :content="navigation">
      <template v-slot:left>
        <router-link class="logo-text underline" to="/"
          >Charlie Browns</router-link
        >
      </template>
      <template v-slot:right>
        <a class="button short" target="_blank" :href="bookOnlineUrl"
          >Book Online</a
        >
      </template>
    </NavigationMenu>
    <router-view />

    <Section wrap class="pad2 footer">
      <Grid middle no-grow>
        <Column
          class="footer-logo"
          :below-laptop-width="100"
          below-laptop-text-center
        >
          Charlie Browns
        </Column>
        <Column fill center :below-laptop-width="100">
          <div class="center-vertical center-horizontally">
            <p>
              Conway house. 8 Cheapside<br />
              Hanley, Stoke on Trent. ST11HE
            </p>
          </div>
        </Column>
        <Column :below-laptop-width="100" below-laptop-text-center>
          <div class="social">
            <a
              href="https://www.facebook.com/CharlieBrownsHairStudioLtd/"
              target="_blank"
              ><img src="/images/icons/facebook.svg"
            /></a>
          </div>
        </Column>
      </Grid>

      <div class="contact">
        <span><em>Tel: </em> <a href="tel:01782274906">01782274906</a></span>
        <span
          ><em>Email: </em>
          <a href="mailto:charliebrownshs@gmail.com"
            >charliebrownshs@gmail.com</a
          ></span
        >
        <span
          ><em>Company #: </em>
          <a
            target="_blank"
            href="https://beta.companieshouse.gov.uk/company/07688992"
            >07688992</a
          ></span
        >
      </div>
    </Section>

    <Section wrap class="pad2 footer">
      <Grid middle no-grow>
        <Column>
        </Column>
        <Column fill center :below-laptop-width="100">
          <div class="center-vertical center-horizontally">
            <a href="https://cyberkiln.com/" target="_blank">
                Crafted by
                <br />
                <img
                  class="cyberkiln"
                  src="/images/cyberkiln.svg"
                />
              </a>
          </div>
        </Column>
        <Column :below-laptop-width="100" below-laptop-text-center>
        </Column>
      </Grid>
    </Section>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      bookOnlineUrl:
        "https://www.fresha.com/charlie-browns-hair-studio-ltd-znfr6t13/booking?menu=true",
      navigation: {
        //image: "./assets/images/logo-chimney.png",
        links: [
          { name: "Visiting", url: "/visiting" },          
          { name: "The Team", url: "/team" },
          { name: "Weddings", url: "/weddings" },
          { name: "Product Range", url: "/products" },
          { name: "Beauty", url: "/beauty" },
          { name: "Prices", url: "/prices" },
          { name: "Reviews", url: "/reviews" },
          { name: "Contact", url: "/contact" }
        ]
      }
    };
  },
  mounted: function() {}
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700&display=swap");
#app {
  font-family: "Nunito", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app,
.navigation-menu {
  // Don't go less than 320px otherwise top menu is a mess
  min-width: 320px;
}

.logo-text,
.footer-logo {
  font-family: "Baskervville", serif;
  font-size: 1.4em;
}
.logo-text {
  margin-right: 1em;

  // Match navigation colors
  color: var(--color-nav-link);
  &:hover {
    // Same color on hover
    color: var(--color-nav-link);
  }

  @include media($breakpoint-below-tablet) {
    font-size: 1.2em;
    margin-right: 0.2em;
  }
}

.reduced .logo-text,
.expanded .logo-text {
  // Match navigation colors
  color: var(--color-nav-link-scrolled);
  &:hover {
    // Same color on hover
    color: var(--color-nav-link-scrolled);
  }
}

a.underline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.08em;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.cyberkiln {
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 35vw; 
    padding-top: 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 35vw;
  }  
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 20vw;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 15vw;
  }
  @media only screen and (min-width: 1201px) {
    width: 15vw;
  }
}

.footer {
  background-color: var(--color-dark-background);

  color: var(--color-foreground-lighter);

  a {
    color: var(--color-dark-background-text) !important;

    &:hover {
      color: var(--color-primary) !important;
    }
  }

  p {
    margin: 0;
    display: inline-block;
  }

  .footer-logo {
    color: var(--color-link);

    margin-bottom: 1em;

    img {
      height: 2em;
      display: inline-block;
    }
  }

  .social {
    @include media($breakpoint-below-laptop) {
      padding-top: 1em;
    }

    a {
      display: inline-block;
      transition: transform ease-in-out 0.3s;
      transform-origin: center center;

      margin-left: 1em;

      @include media($breakpoint-below-laptop) {
        margin-left: 0;
      }

      img {
        width: 2em;
      }

      &:hover {
        transform: scale(1.3);
      }
    }
  }

  .contact {
    text-align: center;

    span {
      display: inline-block;
    }

    span:not(:first-child) {
      @extend .pl;
    }
  }
}
</style>
