<template>
  <div class="reviews top">

    <Section wrap pad center>
    <div data-bid="105532" data-url="https://app.revu.cloud" ><script defer type="application/javascript" src="https://widget.reviewability.com/js/widgetAdv.min.js" async></script></div><script defer class="json-ld-content" type="application/ld+json"></script>
    </Section>

  </div>
</template>

<script>
export default {
  name: "contact",
  data() {
    return {
      splashImageUrl: "/images/backgrounds/map-background.jpg",
    };
  },
};
</script>

<style lang="scss" scoped>


em {
  color: var(--color-primary);
  font-style: normal;
}

.phone {
  padding-top: 3em;
  padding-bottom: 3em;
  h2 {
    font-size: 1em;
    color: var(--color-foreground-lighter);
    text-transform: uppercase;
  }

  h2 + p {
    font-size: 3em;
    margin: 0;
    font-weight: bold;
  }

  p {
    font-size: 1.5em;
  }
}

.hero {
  border-bottom: 2px solid;
  border-color: var(--color-primary);

  h1 {
    margin-bottom: 0.8em;
  }

  h1 + p {
    margin-bottom: 2em;
  }
}
</style>
