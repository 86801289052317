<template>
  <div class="visiting top">
    <!-- Hero -->
    <ViewportHeight top>
      <FullBackground
        class="hero white-out"
        :opacity="0.8"
        :image="splashImageUrl"
      >
        <Section class="big-info" fill center wrap>
          <Grid center fill>
            <Column bottom :column-width="66" :mobile-width="100">
              <div class="downArrow bounce">
                <img :src="downArrowUrl" />
              </div>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="walkUrl" /><br />
            Step Inside<br />
            <em>Charlie Browns</em>
          </h1>
          <p>
            Take a virtual tour of our hair studio. See how we operate, our
            friendly and welcoming environment, a place to rest and enjoy your
            stay. You deserve it!
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="entranceImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="hairDryerUrl" /><br />
            Modern, stylish and full of <br /><em>energy!</em>
          </h1>
          <p>
            As you walk in you will feel at home in our friendly, busy
            atmosphere with music playing and great conversation.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="studioImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="magsUrl" /><br />
            Lean back and <em>relax,</em> <br />
            or chat while you wait
          </h1>
          <p>
            Flick through the variety of magazines during your stay if you
            prefer to just relax in your own company, or why not get ideas for
            your next amazing hair style.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="magazineImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="hairGelUrl" /><br />
            Set the tone with a <br />statement <em>colour</em>
          </h1>
          <p>
            If you fancy a colour change or style we have plenty to choose from
            including semi, permanent and crazy colours!
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="hairImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="hairDyeUrl" /><br />
            Get ready for <br />the <em>Wow </em>factor
          </h1>
          <p>
            Once you have chosen your style we will show you to your chair, get
            you settled in and begin with the transformation. Excited? You
            should be.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="hairDyeImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="coffeeUrl" /><br />
            The fun doesn't <br /><em>stop</em> there
          </h1>
          <p>
            By now you are probably thirsty and want a complementary drink on
            the house. Coffee, cappuccino, tea or maybe just water. We have it
            all. Even that cheeky biscuit everyone loves.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="coffeeImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="barberUrl" /><br />
            Here is where it <br />all comes to <em>life</em>
          </h1>
          <p>
            Once we have let you catch you're breath we go all out to create
            your dream hair style. Shaping, contouring, layering, we work your
            hair to perfection.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="scissorsImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="washUrl" /><br />
            Now we add some <br />tender <em>loving </em>care
          </h1>
          <p>
            Hair care is the most important but most forgotten about when it
            comes to hair styling. We ensure to properly wash, condition and
            apply protective hair care treatments to look after your hair from
            root to tip.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="washImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="curlUrl" /><br />
            Let's add a little <br />
            <em>style </em> to the mix
          </h1>
          <p>
            This is where you will really start to see your hair come to life.
            Going from your 'contemporary bob' and 'short bangs' to your 'one
            length' and '60's flip' we deliver the style of your dreams.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="styleImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="hairCutUrl" /><br />
            Icing on the<br /><em>cake. </em>
          </h1>
          <p>
            No hairstyle would be complete without the finishing touches. So we
            give your hair one last application with drying and styling to make
            sure your hair shines.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="hairDryImageUrl"> </FullBackground>
    </div>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="walletUrl" /><br />
            <em>Voila!</em> You're all ready to go
          </h1>
          <p>
            Now you're ready to rock your new style with confidence. All you
            need to do next is to book your next appointment and to pay the
            bill.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="counterImageUrl"> </FullBackground>
    </div>

    <Section center pad wrap class="big-info">
      <h1>Get in touch <em>today</em></h1>
      <p>
        We love new clients and want to know all about you and how we can help.
      </p>
      <router-link to="/contact" class="button big">Contact Us</router-link>
    </Section>
  </div>
</template>

<script>
export default {
  name: "visiting",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/heros/visiting.jpg",
      downArrowUrl: "/images/down-arrow.svg",
      walkUrl: "/images/walk.svg",
      hairDryerUrl: "/images/hair-dryer.svg",
      entranceImageUrl: "/images/visit/entrance.jpg",
      studioImageUrl: "/images/heros/home.jpg",
      magsUrl: "/images/magazine.svg",
      hairGelUrl: "/images/hair-gel.svg",
      hairDyeUrl: "/images/hair-dye.svg",
      coffeeUrl: "/images/coffee-cup.svg",
      barberUrl: "/images/barbershop.svg",
      washUrl: "/images/hair-washing.svg",
      curlUrl: "/images/curl.svg",
      hairCutUrl: "/images/hair-cut.svg",
      walletUrl: "/images/wallet.svg",
      magazineImageUrl: "/images/visit/magazines.jpg",
      hairImageUrl: "/images/visit/hair.jpg",
      hairDyeImageUrl: "/images/visit/hair-dye.jpg",
      coffeeImageUrl: "/images/visit/coffee.jpg",
      scissorsImageUrl: "/images/visit/scissors.jpg",
      washImageUrl: "/images/visit/wash.jpg",
      productImageUrl: "/images/visit/product.jpg",
      styleImageUrl: "/images/visit/style.jpg",
      hairDryImageUrl: "/images/visit/hair-dry.jpg",
      counterImageUrl: "/images/visit/counter.jpg"
    };
  }
};
</script>

<style lang="scss" scoped>
.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.white-out {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, #fff);
  }
}

.white-in {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    top: 0;
    background: linear-gradient(to top, transparent, #fff);
  }
}

.step-inside {
  img {
    width: 2em;
  }
}

.downArrow {
  @extend .pb2;

  position: relative;
  opacity: 0.5;
  z-index: 2;

  width: 10vh;
  margin: auto;
}
.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
</style>
