<template>
  <div class="visiting top">
    <!-- Hero -->
    <ViewportHeight top>
      <FullBackground
        class="hero blue-out"
        :opacity="0.8"
        :image="relaxImageUrl"
      >
        <Section class="big-info" fill center wrap>
          <Grid center fill>
            <Column bottom :column-width="66" :mobile-width="100">
              <div class="downArrow bounce">
                <img :src="downWhiteArrowUrl" />
              </div>
            </Column>
          </Grid>
        </Section>
      </FullBackground>
    </ViewportHeight>

    <Section class="big-info beauty-logo beauty-section" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="beautyLogoUrl" /><br />
            Rachael's Beauty Room<br />
            <em>at Charlie Browns</em>
          </h1>
          <p>
            Step into an unassuming beauty parlour that has the friendly ambience of a gathering with your besties! Rachael's Beauty Room at Charlie Browns offers a warm welcome to all clients with relaxation and indulgence high on the agenda.
          </p>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="waxingImageUrl"> </FullBackground>
    </div>

    <Section class="big-info beauty-section nail" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            <img :src="nailUrl" /><br />
            Relaxation, indulgence and a<br /><em>friendly ambience!</em>
          </h1>
          <p>
            Take the weight off your feet and let your aches and pains flow away with our relaxing treatments.
          </p>
        </Column>
        <Column>        
          <div class="wrap">
            <h2><u>Price List</u></h2>                
            <section class="question-section">
              <div class="cat-1">
                <input id="tab-1" type="radio" name="tabs" tabindex="1"> 
                <label for="tab-1" >Gel Manicure & Pedicures</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Gel Manicure<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£23</td>
                    </tr>

                    <tr>
                      <th>Gel Pedicure<br><i>35 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£23</td>
                    </tr>

                    <tr>
                      <th>Gel Manicure - French Polish<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£25</td>
                    </tr>

                    <tr>
                      <th>Builder Gel Enhancement<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£26</td>
                    </tr>

                    <tr>
                      <th>Gel Removal<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£6</td>
                    </tr>
                  </table>                    
                </div>
              </div>

              <div class="cat-2">
                <input id="tab-2" type="radio" name="tabs" tabindex="2"> 
                <label for="tab-2" >Nail Extensions</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Acrylic Nails<br><i>125 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£35</td>
                    </tr>

                    <tr>
                      <th>Nail Infill<br><i>90 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£25</td>
                    </tr>

                    <tr>
                      <th>Full on Tips<br><i>90 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£31</td>
                    </tr>

                    <tr>
                      <th>Full on Tips Infill<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£28</td>
                    </tr>

                    <tr>
                      <th>Nail Removal<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£10</td>
                    </tr>
                  </table>
                  <p><i>*Glitter, stamping, foil and other art may be charged extra - please discuss at the time of booking.</i></p>                    
                </div>
              </div>

              <div class="cat-3">
                <input id="tab-3" type="radio" name="tabs" tabindex="3"> 
                <label for="tab-3" >Polish Manicure and Pedicure</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Luxury Pedicure<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£24</td>
                    </tr>

                    <tr>
                      <th>Luxury Manicure<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£24</td>
                    </tr>

                    <tr>
                      <th>Bella Spa Pedicure<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£19</td>
                    </tr>

                    <tr>
                      <th>Bella Spa Manicure<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£19</td>
                    </tr>

                    <tr>
                      <th>Express Pedicure<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£14</td>
                    </tr>

                    <p style="color: #fff;"><i>*All the above include a varnish finish. If you would like a gel veneer finish it is an additional £12.</i><p> 
                    
                    <tr>
                      <th>Varnish Enhancement Toes<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£11</td>
                    </tr>

                    <tr>
                      <th>Varnish Enhancement Hands<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£11</td>
                    </tr>

                    <tr>
                      <th>Child's File and Polish<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£6</td>
                    </tr>
                  </table>                  
                </div>
              </div>

              <div class="cat-4">
                <input id="tab-4" type="radio" name="tabs" tabindex="4"> 
                <label for="tab-4" >Eyebrows & Eyelash Treatments</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Eyebrow Shape<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Eyebrow Tint *<br><i>25 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Eyelash Tint *<br><i>35 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Eyebrow Shape, Eyebrow & Eyelash Tint *<br><i>35 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£20</td>
                    </tr>

                    <tr>
                      <th>Eyebrow Reshape<br><i>(1st Visit) 25 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£14</td>
                    </tr>

                    <p style="color: #fff;"><i>*These treatments require a patch test at least 48 hours before appointment.</i></p> 
                  
                  </table>                  
                </div>
              </div>

              <div class="cat-5">
                <input id="tab-5" type="radio" name="tabs" tabindex="5"> 
                <label for="tab-5" >Eyes</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>LVL Lashes *<br><i>75 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                    <tr>
                      <th>HD Brows *<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£26</td>
                    </tr>

                    <tr>
                      <th>HD Brow Sculpt (Lamination) *<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                    <tr>
                      <th>Express Lashes (Party/Cluster Lash)<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£26</td>
                    </tr>

                    <tr>
                      <th>Eyelash Extensions - Classic Semi-Permanent<br><i>120 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                    <p style="color: #fff;"><i>*These treatments require a patch test at least 48 hours before appointment.</i></p> 
                  
                  </table>                  
                </div>
              </div>

              <div class="cat-6">
                <input id="tab-6" type="radio" name="tabs" tabindex="6"> 
                <label for="tab-6" >Facials</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Facial - Dermalogica Pro 60<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                    <tr>
                      <th>Facial - Dermalogica Pro 30<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£30</td>
                    </tr>
                  </table>                  
                </div>
              </div>

              <div class="cat-7">
                <input id="tab-7" type="radio" name="tabs" tabindex="7"> 
                <label for="tab-7" >Ladies' Facial Waxing</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Lip Wax<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                     <tr>
                      <th>Chin Wax<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Nostril Wax<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                   <tr>
                      <th>Other Facial Waxing<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>
                  </table>                  
                </div>
              </div>

              <div class="cat-8">
                <input id="tab-8" type="radio" name="tabs" tabindex="8"> 
                <label for="tab-8" >Ladies' Intimate Waxing</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Ladies' Leg Waxing - Half Leg Wax<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£16</td>
                    </tr>

                    <tr>
                      <th>Ladies' Leg Waxing - Full Leg Wax<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£21</td>
                    </tr>

                     <tr>
                      <th>Underarm<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£16</td>
                    </tr>

                    <tr>
                      <th>Forearm<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£16</td>
                    </tr>

                   <tr>
                      <th>Bikini Wax<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£13</td>
                    </tr>

                     <tr>
                      <th>G-String Wax<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£17</td>
                    </tr>

                    <tr>
                      <th>Brazilian Waxing<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£26</td>
                    </tr>

                    <tr>
                      <th>Hollywood Waxing<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£31</td>
                    </tr>

                  </table>                  
                </div>
              </div>

              <div class="cat-9">
                <input id="tab-9" type="radio" name="tabs" tabindex="9"> 
                <label for="tab-9" >Massage</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Back, Neck & Shoulders<br><i>75 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£30</td>
                    </tr>

                    <tr>
                      <th>Full Body Massage<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                     <tr>
                      <th>Swedish Massage<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£32</td>
                    </tr>

                    <tr>
                      <th>Swedish Massage<br><i>60 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£47</td>
                    </tr>

                    <tr>
                      <th>Forearm<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£16</td>
                    </tr>

                   <tr>
                      <th>Target Area<br><i>15 minutes - Add to any above, choose from arms, legs, feet, neck & shoulders or back</i></th>
                      <td></td>
                      <td></td>
                      <td>£15</td>
                    </tr>

                  </table>                  
                </div>
              </div>

              <div class="cat-10">
                <input id="tab-10" type="radio" name="tabs" tabindex="10"> 
                <label for="tab-10" >Body Care</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Express Full Body Cleanse & Exfoliation<br><i>20 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£30</td>
                    </tr>

                    <tr>
                      <th>Bella Express Spa Cleanse, Exfoliation & Moisturise with 20 minute Massage<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£35</td>
                    </tr>

                     <tr>
                      <th>Bella Spa - Cleanse, Exfoliation & Moisturise with 30 minute Massage<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£40</td>
                    </tr>

                    <tr>
                      <th>Luxury Spa - Cleanse, Exfoliation, Deep dermal Wrap Moisturise with 30 minute massage & Moisturise with 30 minute Massage<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£45</td>
                    </tr>

                  </table>                  
                </div>
              </div>

              <div class="cat-11">
                <input id="tab-11" type="radio" name="tabs" tabindex="11"> 
                <label for="tab-11" >Male Grooming</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Waxing - Under Arm<br><i>25 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£18</td>
                    </tr>

                    <tr>
                      <th>Waxing - Half Arm<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£16</td>
                    </tr>

                     <tr>
                      <th>Waxing - Full Arm<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£21</td>
                    </tr>

                    <tr>
                      <th>Waxing - Back & Shoulders<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>From £26</td>
                    </tr>

                    <tr>
                      <th>Waxing - Chest<br><i>40 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>From £26</td>
                    </tr>

                    <tr>
                      <th>Nostril Wax<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Eyebrow Shape<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Ear Waxing<br><i>15 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£9</td>
                    </tr>

                    <tr>
                      <th>Eyebrow, Nostril & Ear Waxing<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£20</td>
                    </tr>

                    <tr>
                      <th>Men's Manicure<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£21</td>
                    </tr>

                    <tr>
                      <th>Men's Pedicure<br><i>45 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£21</td>
                    </tr>

                  </table>                  
                </div>
              </div>

              <div class="cat-12">
                <input id="tab-12" type="radio" name="tabs" tabindex="12"> 
                <label for="tab-12" >Tanning</label><!-- end of tab label -->
                <div class="question-wrap">
                  <table>
                    <tr>
                      <th>Spray Tanning & Sunless Tanning<br><i>30 minutes</i></th>
                      <td></td>
                      <td></td>
                      <td>£20</td>
                    </tr>

                  </table>                  
                </div>
              </div>


            </section><!-- End of Questions -->
          </div>
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="beautyStaffUrl"> </FullBackground>
    </div>

    <Section wrap pad class="profile beauty-section">
      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
        <h1>Emily<em>"All Night Dancer"</em></h1>
          <div class="quote">
            <blockquote>
              <p>
                Hi my name is Emily, I’ve been a beautician for about 17 years now and I'm qualified to NVQ level 2 in beauty. I am qualified in Cuccio gel polish, manicures and pedicures, HD brows, LVL lashes, Lycon intimate waxing. 
              </p>

              <p>I always love making my clients happy, no job is too big or too small.</p>
              <cite>Emily</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="emilyImageUrl" />
        </Column>

        <Column :column-width="60" :below-desktop-width="100">
        <h1>Kate<em>"Glitter Queen"</em></h1>
          <div class="quote">
            <blockquote>
              <p>
                I have been in beauty for four years and I absolutely love it. I have qualifications in spray tanning, waxing including hot wax, manicures and pedicures, gel nails, HD brow sculpt and brow and lash tinting.
                I am better known as Glitter Queen, as everything in my life would sparkle and shine.
                I love everything I do, but I love waxing especially. 
              </p>

              <p>I take pride in what I do which makes me feel amazing, I can make ladies feel great. </p>
              <cite>Kate</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="kateImageUrl" />
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="feetImageUrl"> </FullBackground>
    </div>

    <Section center pad wrap class="big-info">
      <h1>Get in touch <em>today</em></h1>
      <p>
        We love new clients and want to know all about you and how we can help.
      </p>
      <router-link to="/contact" class="button big">Contact Us</router-link>
    </Section>
  </div>
</template>


<script>
export default {
  name: "beauty",
  components: {},
  data() {
    return {
      beautyLogoUrl: "/images/beauty/beauty-logo.jpg",
      relaxImageUrl: "/images/beauty/IMG_4483.JPG",
      downWhiteArrowUrl: "/images/down-white-arrow.svg",      
      walkUrl: "/images/walk.svg",
      nailUrl: "/images/beauty/IMG_4516.JPG",
      waxingImageUrl: "/images/beauty/IMG_4594.JPG",
      beautyStaffUrl: "/images/beauty/IMG_4641.JPG",
      magsUrl: "/images/magazine.svg",
      hairGelUrl: "/images/hair-gel.svg",
      hairDyeUrl: "/images/hair-dye.svg",
      coffeeUrl: "/images/coffee-cup.svg",
      barberUrl: "/images/barbershop.svg",
      washUrl: "/images/hair-washing.svg",
      curlUrl: "/images/curl.svg",
      hairCutUrl: "/images/hair-cut.svg",
      walletUrl: "/images/wallet.svg",
      emilyImageUrl: "/images/beauty/IMG_4552.JPG",
      kateImageUrl: "/images/beauty/IMG_4563.JPG",
      feetImageUrl: "/images/beauty/IMG_4491.JPG",
      hairDyeImageUrl: "/images/visit/hair-dye.jpg",
      coffeeImageUrl: "/images/visit/coffee.jpg",
      scissorsImageUrl: "/images/visit/scissors.jpg",
      washImageUrl: "/images/visit/wash.jpg",
      productImageUrl: "/images/visit/product.jpg",
      styleImageUrl: "/images/visit/style.jpg",
      hairDryImageUrl: "/images/visit/hair-dry.jpg",
      counterImageUrl: "/images/visit/counter.jpg"
    };
  }
};
</script>

<style lang="scss" scoped>
.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.white-out {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, #fff);
  }
}

.blue-out {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, #072758);
  }
}

.blue-in {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    top: 0;
    background: linear-gradient(to top, transparent, #072758);
  }
}

.beauty-section {
  color: #fff;
  background-color: #072758;

  p {
    color: #fff;
    opacity: 1;
  }
}

.white-in {
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 25%;
    top: 0;
    background: linear-gradient(to top, transparent, #fff);
  }
}

.step-inside {
  img {
    width: 2em;
  }
}

.beauty-logo {
  img {
    width: 6em;
  }
}

.nail {

  p{
    color: #fff;
  }

  em {
    color: #dbb056;
  }

  img {
    width: 8em;
    border: 0.3em solid #ebe9e9;
    -webkit-box-shadow: 0 10px 25px #fff;
    box-shadow: 0 10px 25px #fff;
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
    margin-top: 0em;
    margin-bottom: 1em;
  }
}

.downArrow {
  @extend .pb2;

  position: relative;
  opacity: 0.5;
  z-index: 2;

  width: 10vh;
  margin: auto;
}
.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.profile {
  em {
    color: var(--color-primary) !important;
  }
  h1 {
    display: inline-block;
    background: #aa8e53;
    padding: 0.3em 1em;
    color: white;
    z-index: 2;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    transform: rotate(1deg);

    @include media($breakpoint-below-tablet) {
      padding: 0.3em;
      font-size: 1.3em;
    }
  }

  img {
    width: 100%;
    border: 0.3em solid white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    transform: rotate(-4deg);
    margin-top: -3em;
    margin-bottom: 5em;

    @include media($breakpoint-below-tablet) {
      margin-top: -2em;
    }
  }
}

.second-image {
  margin-top: 5em;
}

.quote {
  padding: 1em 5em;

  @include media($breakpoint-below-tablet) {
    padding: 1em 0em;
  }

  p{
    color: #fff;
  }

  blockquote {
    font-family: Georgia, serif;
    font-style: italic;

    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #fff;

    @include media($breakpoint-below-tablet) {
      padding-right: 10px;
    }
  }

  blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
  }

  blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }
}

@import url(https://fonts.googleapis.com/css?family=Audiowide);
//colors 
$blue: #b39964;
$blue-dark: #072758;
$blue-light: #072758;

//mixins
@mixin bp($bp, $min-max: min-width) {
    $em: $bp/16;
    @media(#{$min-max}: #{$em}em) {
        @content;
    }
}
//bug fix for general sibling.
body { -webkit-animation: bugfix infinite 1s; } 
@-webkit-keyframes bugfix { from { padding: 0; } to { padding: 0; } }

body {
    background: radial-gradient(ellipse farthest-side at 100% 100%, $blue-light 1%, $blue 30%, $blue-dark 120%);
    background-attachment: fixed;
    padding-top: 2em; 
    overflow-y: scroll;
}


.wrap {
    display: block;
    max-width: 1024px;
    margin:  0 auto 2em; 
    padding: 0 1em;
    width: 100%;

    h2 {
      font-size: 2.2em;
      opacity: 1;
    }
    
    @include bp(700) {
        padding: 3em; 
    }
}

p { 
    color: #555; 
    text-shadow: 1px 1px 1px #fff;
}

a, a:visited {
    color: darken($blue, 15%);
    text-decoration: none;
}

//questions styles
.question-section {
    background: #0a2143; 
    box-shadow: 0 2px 5px rgba(#444, .4);
    display: inline-block;
    font-size: 1rem;
    margin-bottom: 1em; 
    padding: 1em;
    position: relative;
    vertical-align: top;
    width: 100%;
    @include bp(800) {
        font-size: .75rem;
        padding: 1em 1.5em; 
    }
    
    @include bp(1000) {
        font-size: 1rem;
    }
    
    h1 {
        font-family: "Audiowide", cursive; 
        color: $blue-dark;
        font-size: 1.25em;
        font-size: 5.5vw;
        font-weight: 700;
        margin-bottom: .25em;
        @include bp(516) {
            font-size: 2.25em;
            font-size: 4.9vw;
        }
        
        @include bp(1080) {
            font-size: 3.25em;
        }
    }
    
    & > p,
    & > h1 { text-align: center; }
    
    & > p { 
        font-size: 0.667em;
        font-size: 2.8vw;
        margin-bottom: 1em;
        
        @include bp(355) {
            font-size: 0.667em;
        }

        @include bp(500) {
            font-size: 1em;
        }

        @include bp(1300) {
            font-size: 1.15em;
        }
    }
}





//tabs
div[class^="cat"] {
    width: 100%;
    //tabs lables
    & > label {
        background: darken($blue, 5%);
        color: #fff; 
        cursor: pointer;
        display: block;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 0.5em;
        padding: 0.75em 0.5em; 
        transition: all 0.55s ;
        &:hover { background: $blue; }
        @include bp(800) {
            font-size: 1.25em;
        }
    }
    //hide catagory radios
    & > input { 
        position: absolute;
        z-index: -999;
        &:focus + label { 
            background: darken($blue, 15%);
            letter-spacing: 1px; 
        }
    }
    //hide questions till tab is picked 
    .question-wrap {
        height: 100%;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all .75s cubic-bezier(0.19, 1, 0.22, 1);
        width: 100%; 
    }
    //open accordian style
    & > input:checked ~ .question-wrap { 
        max-height: 1000px;
        opacity: 1;
        transition: all 1.95s cubic-bezier(0.19, 1, 0.22, 1) ;
    }
    
    
    
    ///////////////////////////////
    //question accordian styles //
    /////////////////////////////
    
    @at-root {
        
        .question-wrap {
            .question {
                margin: 1em 0; 
            }

            p {
              color: #fff;
              text-shadow: 1px 1px 1px #fff;
              font-size: 1em;
            }

            table {
              width: 100%;
              font-size: 1em;
              line-height: 1.2em;
              color: #000;

              p {
                color: rgb(0, 0, 0);
                text-shadow: 1px 1px 1px #fff;
                font-size: 1em;
              }

              @include media($breakpoint-below-tablet) {
                font-size: 0.7em;
              }

              @include media($breakpoint-tiny-mobile) {
                th,
                td {
                  padding: 0.7em !important;
                }
              }

              border-collapse: collapse;

              border: 1px solid black;
              padding: 1em;

              tr {
                width: 100%;
                &:nth-child(even) {
                  background: #f3f3f3;
                }
                &:nth-child(odd) {
                  background: #ddd;
                }
              }

              tr:first-child {
                background: #ddd;
              }

              .header {
                th strong {
                  font-size: 2.5em;
                  vertical-align: middle;

                  @include media($breakpoint-below-laptop) {
                    font-size: 1.5em;
                  }
                }

                td,
                th {
                  padding: 1em;
                }
              }

              th,
              td {
                padding: 1em;
                //padding-bottom: 0;
              }

              tr:last-child {
                td,
                th {
                  padding-bottom: 2em;
                }
              }

              tr:nth-child(2) {
                td,
                th {
                  padding-top: 2em;
                }
              }

              td:not(:first-child),
              th:not(:first-child) {
                text-align: center;
                width: 1px;
                white-space: nowrap;
              }

              th:first-child {
                font-weight: bold;
                text-align: left;
              }
            }
            
            label {
                color: lighten(#085078, 5%); 
                cursor: pointer;
                display: block;
                font-weight: bold;
                margin-bottom: 0.5em;

                @include bp(800) {
                    font-size: 1.25em;
                }
            }
            
            input { 
                position: absolute;
                z-index: -999;
                top: -10000px;
                &:focus + label { 
                    color: darken($blue-dark, 5%);
                } 
            }
            
            input:not(:checked) ~ p {
                height: 100%;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                transition: all .75s cubic-bezier(0.19, 1, 0.22, 1); 
            }
            
            //open accordian style
           input:checked ~ p { 
                max-height: 500px;
                opacity: 1;
                transition: all 1.95s cubic-bezier(0.19, 1, 0.22, 1);
            }
            
        }
    }
}


input:focus {
    border: 3px solid red;
    outline: 3px solid red;
    background: red;
}

a:focus { 
    color: $blue-dark;
    font-weight: bold;
    outline: none;
}
</style>
