<template>
  <div class="team top">
    <!-- Hero -->
    <Section class="hero">
      <img :src="splashImageUrl" />
    </Section>

    <Section class="big-info step-inside" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>Meet The Team</h1>
          <p>For the ultimate hair styling destination look no further than Charlie Brown’s Hair Studio whether you want a classic cut and colour, a sexy revamp or a fresh modern makeover, Charlie Browns can breath new life into your old hairstyle.</p>
          <p>Offering a friendly and welcoming atmosphere, combined with experience and talented hair technicians, you can rest assured your in safe hands.</p>
          <p>Not only will you leave looking and feeling fantastic, you ooze confidence, class and charisma from both inside and out.</p>
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile">
      <h1>
        Charlie
        <em>"The Gaffer"</em>
      </h1>
      <img :src="charlie1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>I’m Charlie and I’m a creative style director and colour</p>
              <p>technician. Within the last 17 years I’ve attended the</p>
              <p>
                Schwarzkopf academy for various colour and cutting courses which
                involve a Diploma in creative cutting and colouring, barbering
                and balayage/ombré specialist.
              </p>
              <p>
                I’m Balmain hair extensions trained and after attending many GHD
                styling classes and the best thing about the hairdressing industry
                is you never stop learning. An amazing part of my job is I get
                the pleasure of being involved in many couples big day along
                with Martyn as we are bridal specialists.
              </p>

              <p>
                I live, breathe and dream hair and get to work every day with my
                best friends the Charlie Browns dream team.
              </p>
              <cite>Charlie</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="charlie2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile gradient-back invert">
      <h1>
        Martyn
        <em>"The Entertainer"</em>
      </h1>
      <img :src="martyn1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                Hi I'm Martyn and I'm creative style director at the salon, I'm
                so fortunate that my passion and love for hair is actually my
                job! I have qualifications in hairdressing level 1,2 and 3, also
                level 1, 2 and 3 barbering covering haircuts to cut throat
                shaves,i have a vast knowledge and experience on colouring hair,
                attending various advanced colouring courses and colour
                correction seminars within Schwarzkopf.
              </p>
              <p>
                I Specialise in bridal hair and I have the pleasure of working
                with Charlie on creating many brides perfect wedding day hair!
                I've had the pleasure of working with Rachel for over 12 years
                and to this day we still learn from each other, that's the
                beauty of this industry there are no limits in learning
              </p>

              <p>I'm so proud to part of Charlie Browns.</p>
              <cite>Martyn</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="martyn2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile">
      <h1>
        Rachel
        <em>"A.K.A Rachy!"</em>
      </h1>
      <img :src="rach1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                I’m the salon's master stylist and deputy manager. I’ve been
                working in salons since 2004 and have had the pleasure to have
                worked at Charlie Browns for the last 6 years. I’m a qualified
                level 1, 2 and 3 hairdresser. Along with level 1, 2, 3 barber
                and cut throat shaves, specialise in ballmain hair Extensions,
                and have done stage work in competitions, also attended many
                extra cut and colour training sessions with in Schwarzkopf and
                GHD.
              </p>
              <p>
                I absolutely love my job (I literally couldn’t imagine doing
                anything else) and take great pleasure in making my client look
                and feel great! I’m also a massive believer that we never stop
                learning in this industry. I welcome all old and new clients
                with open arms.
              </p>

              <p>I wouldn’t be where I am with out you. So thank you.</p>
              <cite>Rachel</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="rach2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile gradient-back invert">
      <h1>
        Hayley
        <em>"Foil Queen"</em>
      </h1>
      <img :src="hayleyImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                Hi I’m Hayley, salon stylist. I’ve been playing with hair for as long as I can remember, 
                until my dream came true and I qualified 14 years ago when I completed my level 2 and 3 NVQ. 
                I’m obsessed with foiling and creative choppy cuts are my favourite. 
              </p>
              <p>
                Since joining Charlie Browns 
                I have trained in Balmain hair extensions and I’m in my element when creating the long locks for 
                my clients. If your sat in my chair you can rest assured you’re in good hands.
              </p>
              <cite>Hayley</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="hayley2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile">
      <h1>
        Amy
        <em>"Miss Chatterbox!"</em>
      </h1>
      <img :src="amy1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                Hi I am Amy, master stylist at Charlie Browns. 
                I love learning and education, I have a vast knowledge 
                in all aspects of hair and a variety of qualifications in hair dressing, 
                barbering, customer service and team leading. Including Schwartzkopf workshops and future trends. 
                I am currently studying in teaching and training and I can not wait to share my skills and experience on to others. 
              </p>

              <p>I love all aspects of what I do especially building relationships with my clients.</p>
              <cite>Amy</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="amy2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile gradient-back invert">
      <h1>
        Morgan
        <em>"Morgie Bear"</em>
      </h1>
      <img :src="morgan1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                I am the stylist in training, I’ll be the one meeting and greeting you and making your brews. 
                I am currently doing my level 2 at college and love experimenting with hair art, when I’m not assisting the team.                 
              </p>
              <p>
                I’m loving my new adventure at Charlie Browns and look forward to welcoming you in to the salon.
              </p>
              <cite>Morgan</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="morgan2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section wrap pad class="profile">
      <h1>
        Sam
        <em>"Sambo!"</em>
      </h1>
      <img :src="sam1ImageUrl" />

      <Grid>
        <Column :column-width="60" :below-desktop-width="100">
          <div class="quote">
            <blockquote>
              <p>
                Hi I’m Sam, Admin and Tech Support. I’m the one who helps keep Charlie’s 
                head on when it comes to the technical side. Bookkeeping and admin which 
                means Charlie can spend more time training the team and on the shop floor.  
              </p>

              <p>
                I’m also a master of all skills and helps on the shop floor when needed. No task too small or too big.
              </p>
              <cite>Sam</cite>
            </blockquote>
          </div>
        </Column>
        <Column middle class="second-image">
          <img :src="sam2ImageUrl" />
        </Column>
      </Grid>
    </Section>

    <Section class="hero">
      <img :src="bottomsplashImageUrl" />
    </Section>
  </div>
</template>

<script>
export default {
  name: "visiting",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/team/group.jpg",
      charlie1ImageUrl: "/images/team/charlie1.jpg",
      charlie2ImageUrl: "/images/team/charlie2.jpg",
      martyn1ImageUrl: "/images/team/martyn1.jpg",
      martyn2ImageUrl: "/images/team/martyn2.jpg",
      rach1ImageUrl: "/images/team/rach1.jpg",
      rach2ImageUrl: "/images/team/rach2.jpg",
      hayleyImageUrl: "/images/team/hayley1.jpg",
      hayley2ImageUrl: "/images/team/hayley2.jpg",
      amy1ImageUrl: "/images/team/amy1.jpg",
      amy2ImageUrl: "/images/team/amy2.jpg",
      morgan1ImageUrl: "/images/team/morgan1.jpg",
      morgan2ImageUrl: "/images/team/morgan2.jpg",
      sam1ImageUrl: "/images/team/sam1.jpg",
      sam2ImageUrl: "/images/team/sam2.jpg",
      bottomsplashImageUrl: "/images/team/teamfun.jpg",
    };
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.invert .quote blockquote {
  color: rgb(243, 230, 230);
}

.second-image {
  margin-top: 5em;
}

.quote {
  padding: 1em 5em;

  @include media($breakpoint-below-tablet) {
    padding: 1em 0em;
  }

  blockquote {
    font-family: Georgia, serif;
    font-style: italic;

    margin: 0.25em 0;
    padding: 0.35em 40px;
    line-height: 1.45;
    position: relative;
    color: #383838;

    @include media($breakpoint-below-tablet) {
      padding-right: 10px;
    }
  }

  blockquote:before {
    display: block;
    padding-left: 10px;
    content: "\201C";
    font-size: 80px;
    position: absolute;
    left: -20px;
    top: -20px;
    color: #7a7a7a;
  }

  blockquote cite {
    color: #999999;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  blockquote cite:before {
    content: "\2014 \2009";
  }
}

.profile {
  em {
    color: var(--color-primary) !important;
  }
  h1 {
    display: inline-block;
    background: black;
    padding: 0.3em 1em;
    color: white;
    z-index: 2;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    transform: rotate(1deg);

    @include media($breakpoint-below-tablet) {
      padding: 0.3em;
      font-size: 1.3em;
    }
  }

  img {
    width: 100%;
    border: 0.3em solid white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    transform: rotate(-4deg);
    margin-top: -3em;
    margin-bottom: 5em;

    @include media($breakpoint-below-tablet) {
      margin-top: -2em;
    }
  }
}
</style>
