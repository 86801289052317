import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Products from "../views/Products.vue";
import Visiting from "../views/Visiting.vue";
import Beauty from "../views/Beauty.vue";
import Team from "../views/Team.vue";
import Reviews from "../views/Reviews.vue";
import Weddings from "../views/Weddings.vue";
import Prices from "../views/Prices.vue";
import GridDemo from "../views/GridDemo.vue";
import DnaScrollDown from "@/modules/dna.scroll-down.js";

Vue.use(VueRouter);

const routes = [
  // 404 page
  { path: "*", component: Home },
  {
    path: "/grid",
    name: "grid",
    meta: { title: "Grid Demo" },
    component: GridDemo
  },
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: { title: "Contact Us" }
  },
  {
    path: "/beauty",
    name: "beauty",
    component: Beauty,
    meta: { title: "Beauty" }
  },
  {
    path: "/products",
    name: "products",
    component: Products,
    meta: { title: "Product Range" }
  },
  {
    path: "/visiting",
    name: "visiting",
    component: Visiting,
    meta: { title: "Visiting" }
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: { title: "The Team" }
  },
  {
    path: "/weddings",
    name: "weddings",
    component: Weddings,
    meta: { title: "Weddings" }
  },
  {
    path: "/reviews",
    name: "reviews",
    component: Reviews,
    meta: { title: "Reviews" }
  },
  {
    path: "/prices",
    name: "prices",
    component: Prices,
    meta: { title: "Prices" }
  }
];

// Website title
var title = "Charlie Browns Hair Studio";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

const closeMenu = () => {
  // Add the desired class to all top menus
  var topMenus = document.querySelectorAll("[data-top-menu-class]");

  // For each menu...
  topMenus.forEach(function(menu) {
    // Get the value of the data-top-menu-class attribute
    var menuClass = menu.getAttribute("data-top-menu-class");

    // Add/remove class
    menu.classList.remove(menuClass);
  });
};

router.afterEach(() => {
  // Bit hackish way to wait for page to mount then hook into the new UI
  // Works for now and saves having to run this on ever pages mounted/unmounted
  setTimeout(() => {
    DnaScrollDown.Unhook();
    DnaScrollDown.Hook();
  }, 1);

  closeMenu();
});

// Set title of page on load
router.beforeEach((to, from, next) => {
  // Add site title to end of page title
  var pageTitle = to.meta.title ? to.meta.title + " - " : "";

  document.title = pageTitle + title;

  // Set current page name
  Vue.prototype.$pageName = to.name;

  next();
});

export default router;
