<template>
  <div class="weddings top">
    <!-- Client B -->
    <Section class="big-info pt2 works gradient-back" fill center wrap pad>
      <Grid center middle fill>
        <Column :column-width="80" :below-laptop-width="100">
          <h1>
            Bridal &amp;<br />
            <em>Specialist Album</em>
          </h1>
          <p>
            Your Wedding day is the most beautiful and amazing day of your life.
            We are always immensley overjoyed when a client comes to us to have
            her hair set for the big day.
          </p>
        </Column>
      </Grid>

      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="bridal4Aurl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal4Curl" />
        </Column>
      </Grid>
    </Section>

    <div class="big-image">
      <FullBackground :image="bridal4Burl"> </FullBackground>
    </div>

    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="bridal1Burl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal1Aurl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal1Curl" />
        </Column>
      </Grid>
    </Section>

    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="bridal2Burl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal2Aurl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal2Curl" />
        </Column>
      </Grid>
    </Section>

    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="bridal3Burl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal3Aurl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal3Curl" />
        </Column>
      </Grid>
    </Section>

    <Section class="big-info works gradient-back" fill center wrap pad>
      <Grid center>
        <Column :below-laptop-width="80">
          <img :src="bridal5Burl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal5Aurl" />
        </Column>
        <Column :below-laptop-width="80">
          <img :src="bridal5Curl" />
        </Column>
      </Grid>
    </Section>

    <Section center pad wrap class="big-info">
      <h1>Feel amazing on your <em>Special day?</em></h1>
      <p>
        Want your hair to be as <b>amazing</b> as your day? Want to feel
        <em>great</em> about yourself?
      </p>
      <router-link to="/visiting" class="button big">Visit Us</router-link>
    </Section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/heros/home.jpg",
      iconHairUrl: "/images/icons/hair.svg",
      luke1Url: "/images/hair/luke1.jpg",
      luke2Url: "/images/hair/luke2.jpg",
      luke3Url: "/images/hair/luke3.jpg",
      luke4Url: "/images/hair/luke4.jpg",
      luke5Url: "/images/hair/luke5.jpg",
      clientB1Url: "/images/hair/clientB1.jpg",
      clientB2Url: "/images/hair/clientB2.jpg",
      clientB3Url: "/images/hair/clientB3.jpg",
      bridal1Aurl: "/images/bridal/bridal_1a.jpg",
      bridal1Burl: "/images/bridal/bridal_1b.jpg",
      bridal1Curl: "/images/bridal/bridal_1c.jpg",
      bridal2Aurl: "/images/bridal/bridal_2a.jpg",
      bridal2Burl: "/images/bridal/bridal_2b.jpg",
      bridal2Curl: "/images/bridal/bridal_2c.jpg",
      bridal3Aurl: "/images/bridal/bridal_3a.jpg",
      bridal3Burl: "/images/bridal/bridal_3b.jpg",
      bridal3Curl: "/images/bridal/bridal_3c.jpg",
      bridal4Aurl: "/images/bridal/bridal_4a.jpg",
      bridal4Burl: "/images/bridal/bridal_4b.jpg",
      bridal4Curl: "/images/bridal/bridal_4c.jpg",
      bridal5Aurl: "/images/bridal/bridal_5a.jpg",
      bridal5Burl: "/images/bridal/bridal_5b.jpg",
      bridal5Curl: "/images/bridal/bridal_5c.jpg"
    };
  }
};
</script>

<style lang="scss">
.weddings .background-item {
  background-color: black !important;
}

.image-text {
  img {
    width: 80%;
  }
}

.big-image {
  height: 50vw;

  @include media($breakpoint-laptop) {
    height: 70vw;
  }
  @include media($breakpoint-tablet) {
    height: 80vw;
  }
  @include media($breakpoint-below-tablet) {
    height: 90vw;
  }
}

.works {
  img {
    width: 100%;
    border: 0.3em solid white;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);

    @include media($breakpoint-below-laptop) {
      margin-bottom: -2em;
    }
  }

  .col:first-child img {
    transform: rotate(-4deg);
  }

  .col:last-child img {
    transform: rotate(4deg);

    @include media($breakpoint-below-laptop) {
      margin-bottom: 2em;
    }
  }
}
</style>
